<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          Password Reset
        </v-card-title>

        <!-- title -->
        <v-card-text v-if="!loginLoading">
          <div>
            <p class="mb-2 text-center">
              Enter your email to generate a reset code.
            </p>
          </div>
        </v-card-text>

        <!-- login form -->
        <v-card-text
          v-if="loginLoading"
          class="fadeOut text-center d-all justify-center mx-auto my-auto"
        >
          <v-progress-circular
            :size="50"
            color="success"
            indeterminate
            class="d-all text-center mx-auto my-auto"
          ></v-progress-circular>
        </v-card-text>
        <v-card-text>
          <v-snackbar
            v-model="snackbarShow"
            :color="snackbarType"
            :absolute="true"
            :top="true"
            :timeout="snackbarTimeout"
          >
            <v-layout
              align-center
              pr-4
            >
              <v-icon
                class="pr-3"
                dark
                large
              >
                {{ snackbarIcon }}
              </v-icon>
              <v-layout column>
                <div>{{ snackbarText }}</div>
              </v-layout>
            </v-layout>
          </v-snackbar>
          <v-form
            v-if="!loginLoading && !user.isLoggedin"
            id="login-form"
            @submit.prevent="login"
          >
            <v-text-field
              v-model="user.email"
              outlined
              color="success"
              label="Email"
              type="email"
              name="email"
              placeholder="Email"
              class="email-input email-extra mb-3"
              value
              hide-details
              required
            ></v-text-field>
            <v-btn
              type="submit"
              form="login-form"
              block
              color="success"
              class="mt-6"
              :loading="loginLoading"
            >
              Reset Password
            </v-btn>
          </v-form>
        </v-card-text>
        <!-- create new account  -->
        <v-card-text
          v-if="!loginLoading && !user.isLoggedin"
          class="d-flex align-center justify-center flex-wrap mt-2"
        >
          <router-link :to="{name:'login'}">
            Login
          </router-link>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiTwitter,
  mdiGithub,
  mdiGoogle,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiAccountRemoveOutline,
} from '@mdi/js'
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  setup() {
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    return {
      socialLink,
      loginLoading: false,
      snackbarText: '',
      snackbarType: 'success',
      snackbarTimeout: 5000,
      snackbarShow: false,
      snackbarIcon: '',

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  data() {
    return {
      user: {},
    }
  },
  methods: {
    login() {
      this.loginLoading = true
      firebase.auth().sendPasswordResetEmail(this.user.email)
        .then(() => {
          this.snackbarShow = true
          this.snackbarType = 'success'
          this.snackbarText = 'Email sent'
          this.loginLoading = false
        })
        .catch(err => {
          console.log(err)
          this.loginLoading = false
          this.snackbarShow = true
          this.snackbarIcon = mdiAccountRemoveOutline
          this.snackbarType = 'error'
          this.snackbarText = err.message
        })
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

.text-danger {
  color: #EF5350;
}
</style>
